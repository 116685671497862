import { PatchType, TextRun } from 'docx'
import { utcToZonedTime } from 'date-fns-tz'
import { format } from 'date-fns'

const makeZonedDate = (date: Date) => utcToZonedTime(date, 'Europe/Berlin')
export function makeDateString(date?: Date | null) {
  if (!date) {
    return ''
  }
  const zonedDate = makeZonedDate(date)
  return format(zonedDate, 'dd.MM.yyyy')
}
export function makeDateTimeString(date?: Date | null) {
  if (!date) {
    return ''
  }
  const zonedDate = makeZonedDate(date)
  return format(zonedDate, 'dd.MM.yyyy HH:mm')
}

type Patch = { value: string, patch: { type: 'paragraph', children: TextRun[] } }
export function makePatch(value?: string | null): Patch {
  return {
    value: value ?? '',
    patch: {
      type: PatchType.PARAGRAPH,
      children: [new TextRun(value ?? '')],
    },
  }
}

export function makePatchDocument(patches: Record<string, Patch>) {
  const patchOptions = Object.keys(patches).reduce((value: Record<string, Patch['patch']>, key) => {
    value[key] = patches[key].patch
    return value
  }, {})

  return {
    keepOriginalStyles: true,
    patches: patchOptions,
  }
}
