<script lang="ts" setup>
import type { PrismaOrderBy, RouterOutput } from '~/types'
import type { TableConfig } from '~/components/TheTable.vue'

type DocumentTemplate = RouterOutput['documentTemplates']['findManyDocumentTemplates']['data'][number]

const props = defineProps<{
  actions?: Action[]
  defaultOrderBy?: PrismaOrderBy<'documentTemplate'>[]
}>()
defineEmits<{ (e: 'update', entity: DocumentTemplate): void }>()

const { fulltextSearchValue, where } = useFilterData({
  filters: ['/name'],
})

const defaultOrderBy: ComputedRef<PrismaOrderBy<'documentTemplate'>[]> = computed(() => props.defaultOrderBy ?? [{ name: 'asc' }])
const orderBy: Ref<PrismaOrderBy<'documentTemplate'>[] | undefined> = ref()
const { serverSidePaginationTableProps, serverSidePagination, watchForPageCount } = useTablePagination()
const queryParams = useQueryParams('documentTemplate', {
  where: computed(() => where.value),
  pagination: serverSidePagination,
  orderBy: computed(() => orderBy.value ?? defaultOrderBy.value),
})

const { documentTemplateQuery } = useQuery()
const { data: documentTemplateData, isLoading: areDocumentTemplatesLoading } = documentTemplateQuery.findMany(queryParams)
const documentTemplates = computed(() => documentTemplateData.value?.data)
watchForPageCount(computed(() => documentTemplateData.value?.count ?? 0))

const { $i18n } = useNuxtApp()
const tableConfig: TableConfig<DocumentTemplate> = {
  columns: [
    {
      title: $i18n.t('documentTemplate.name.label'),
      key: 'name',
      width: '250px',
      sorter: 'default',
    },
    {
      title: $i18n.t('documentTemplate.schema.label'),
      key: 'schema',
      width: '200px',
      render: ({ schema }) => h('div', { class: 'italic' }, $i18n.t(`template.dataSchema.${schema}`)),
      sorter: 'default',
    },
  ],
  actionButtons: (doc) => {
    const buttons: Action[] = []
    if (doc.previewPath) {
      buttons.push('preview')
    }
    if (doc.filePath) {
      buttons.push('download')
    }
    if (props.actions) {
      buttons.push(...props.actions)
    }
    return buttons
  },
}
</script>

<template>
  <TheSearchBar v-model="fulltextSearchValue" />
  <TheTable
    v-bind="serverSidePaginationTableProps"
    :data="documentTemplates"
    :table-config="tableConfig"
    :is-loading="areDocumentTemplatesLoading"
    @preview="({ id }) => navigateTo(`/api/file/template/preview/${id}`, { open: { target: '_blank' } })"
    @download="({ id }) => navigateTo(`/api/file/template/${id}`, { open: { target: '_blank' } })"
    @update="(doc) => $emit('update', doc)"
    @update:sorter="(sorter) => {
      orderBy = useTableSorter<'documentTemplate'>(sorter)
      serverSidePaginationTableProps.controlledPaginationSettings.page = 1
    }"
  />
</template>
