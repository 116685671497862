<script lang="ts" setup>
import DOCUMENT_TEMPLATE_SCHEMA, { DOCUMENT_TEMPLATE_MOCK_DATA, type DocumentTemplateSchema } from '~/server/templates/documents/schemas'

const schemas = Object.entries(DOCUMENT_TEMPLATE_SCHEMA).map(([key, entries]) => {
  const patches = entries.parse(DOCUMENT_TEMPLATE_MOCK_DATA[key as DocumentTemplateSchema])
  return {
    key,
    patches: Object.entries(patches).map(([key, patch]) => ({ key, value: patch.value })),
  }
})
</script>

<template>
  <TheDataCard>
    <n-collapse :accordion="true">
      <n-collapse-item
        v-for="schema in schemas"
        :key="schema.key"
      >
        <template #header>
          <p class="text-base">
            {{ $t('documentTemplate.schema.label') }}: {{ $t(`template.dataSchema.${schema.key}`) }}
          </p>
        </template>
        <n-alert :show-icon="false" class="mb-2">
          {{ $t('documentTemplate.variables.hint') }}
        </n-alert>
        <n-table :single-line="false" size="small">
          <thead>
            <tr>
              <th class="w-[250px]">
                {{ $t('template.key') }}
              </th>
              <th>{{ $t('template.example') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="patch in schema.patches" :key="patch.key">
              <td>
                {{ $t('template.key.wrapper', { key: patch.key }) }}
              </td>
              <td>{{ patch.value }}</td>
            </tr>
          </tbody>
        </n-table>
      </n-collapse-item>
    </n-collapse>
  </TheDataCard>
</template>
