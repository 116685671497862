import { z } from 'zod'
import { fakerDE as faker } from '@faker-js/faker'
import { makeDateString, makeDateTimeString, makePatch } from './makePatch'
import useAddressString from '~/utils/useAddressString'
import { mobilePhoneSchema } from '~/server/schemas'

/*
 * Template Data Schemas
 * These schemas define what data is avalible for each message. Also include a default option to properly render previews.
 */
const accountDataSchema = z.object({
  mobilePhone: mobilePhoneSchema.nullish(),
  landLinePhone: z.string().nullish(),
})
const addressSchema = z.object({
  street: z.string(),
  postcode: z.string(),
  city: z.string(),
  country: z.string().optional(),
})
const personDataSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  account: accountDataSchema,
  birthDate: z.coerce.date().nullish(),
  address: addressSchema.nullable(),
  creatorPhoneNumber: z.string().nullish(),
})
const mockPerson: z.infer<typeof personDataSchema> = {
  firstName: faker.person.firstName(),
  lastName: faker.person.lastName(),
  account: {
    mobilePhone: faker.helpers.fromRegExp('+491521[0-9]{7}'),
    landLinePhone: faker.helpers.fromRegExp('+491521[0-9]{7}'),
  },
  birthDate: faker.date.past(),
  address: {
    street: faker.location.street(),
    postcode: faker.location.zipCode(),
    city: faker.location.city(),
  },
  creatorPhoneNumber: faker.helpers.fromRegExp('+491521[0-9]{7}'),
}

export const caseDataSchema = z.object({
  patient: personDataSchema,
  referenceId: z.string(),
  type: z.string(),
  infectionContactDate: z.coerce.date().nullish(),
  quarantineStart: z.coerce.date().nullish(),
  quarantineEnd: z.coerce.date().nullish(),
  travelLocation: z.string().nullish(),
  travelReturnDate: z.coerce.date().nullish(),
  disease: z.object({ name: z.string() }),
  diseaseVariant: z.object({ name: z.string() }).nullish(),
  infectionAddress: addressSchema.nullish(),
  creatorPhoneNumber: z.string().nullish(),
})
const mockCase: z.infer<typeof caseDataSchema> = {
  referenceId: 'CVD-2023-783',
  patient: mockPerson,
  type: 'index',
  infectionContactDate: faker.date.recent(),
  quarantineStart: faker.date.recent(),
  quarantineEnd: faker.date.soon(),
  travelLocation: faker.location.country(),
  travelReturnDate: faker.date.recent(),
  disease: { name: 'Corona' },
  diseaseVariant: { name: 'Delta' },
  infectionAddress: {
    street: faker.location.street(),
    postcode: faker.location.zipCode(),
    city: faker.location.city(),
  },
  creatorPhoneNumber: faker.helpers.fromRegExp('+491521[0-9]{7}'),
}

/*
 * Template Schemas
 * Define new document template schemas
 */
const DOCUMENT_TEMPLATE_SCHEMA = {
  case: caseDataSchema.transform(({ patient, disease, diseaseVariant, ...args }) => ({
    'now': makePatch(makeDateString(new Date())),
    'referenceId': makePatch(args.referenceId),
    'caseType': makePatch(args.type),
    'diseaseName': makePatch(disease.name),
    'diseaseVariantName': makePatch(diseaseVariant?.name),
    'infectionContactDate': makePatch(makeDateString(args.infectionContactDate)),
    'infectionLocation': makePatch(useAddressString(args.infectionAddress)),
    'patient.firstName': makePatch (patient.firstName),
    'patient.lastName': makePatch(patient.lastName),
    'patient.mobilePhone': makePatch(patient.account.mobilePhone),
    'patient.landLinePhone': makePatch(patient.account.landLinePhone),
    'patient.birthDate': makePatch(makeDateString(patient.birthDate)),
    'patient.address': makePatch(useAddressString(patient.address)),
    'quarantineStart': makePatch(makeDateTimeString(args.quarantineStart)),
    'quarantineEnd': makePatch(makeDateTimeString(args.quarantineEnd)),
    'quarantineStartShort': makePatch(makeDateString(args.quarantineStart)),
    'quarantineEndShort': makePatch(makeDateString(args.quarantineEnd)),
    'travelLocation': makePatch(args.travelLocation),
    'travelReturnDate': makePatch(makeDateString(args.travelReturnDate)),
    'creatorPhoneNumber': makePatch(args.creatorPhoneNumber),
  })),
  person: personDataSchema.transform(({ account, address, ...args }) => ({
    now: makePatch(makeDateString(new Date())),
    firstName: makePatch(args.firstName),
    lastName: makePatch(args.lastName),
    mobilePhone: makePatch(account.mobilePhone),
    landLinePhone: makePatch(account.landLinePhone),
    birthDate: makePatch(makeDateString(args.birthDate)),
    address: makePatch(useAddressString(address)),
    creatorPhoneNumber: makePatch(args.creatorPhoneNumber),
  })),
} as const

export const DOCUMENT_TEMPLATE_MOCK_DATA: Record<DocumentTemplateSchema, any> = {
  case: mockCase,
  person: mockPerson,
}

export type DocumentTemplateSchema = keyof typeof DOCUMENT_TEMPLATE_SCHEMA
export default DOCUMENT_TEMPLATE_SCHEMA
