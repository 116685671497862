<script lang="ts" setup>
import DocumentEditIcon from '~/assets/icons/topics/documentEdit.svg'

const { canUse } = useAuthorization()
const { openDocumentTemplatePopup } = useOpeners()
</script>

<template>
  <ThePage :title="$t('navigation.templates.documents')">
    <template #icon>
      <DocumentEditIcon />
    </template>
    <template v-if="canUse('documents.template.create')" #header-extra>
      <n-button type="primary" @click="openDocumentTemplatePopup.open({ mode: 'create' })">
        <template #icon>
          <AddIcon />
        </template>
        {{ $t('documentTemplate.page.create') }}
      </n-button>
    </template>

    <DocumentTemplateVariableList />
    <DocumentTemplateOverviewTable
      :actions="canUse('documents.template.update') ? ['update'] : []"
      @update="({ id }) => openDocumentTemplatePopup.open({ mode: 'update', data: { id } })"
    />

    <LazyDocumentTemplateCreateOrUpdateContainer
      :opener="openDocumentTemplatePopup"
    />
  </ThePage>
</template>
